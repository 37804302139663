import { useState } from "react";

const ArticleHeadline = ({
  notFound,
  forbidden,
  articleData,
  featuredImageURL,
  photoInfo,
}) => {
  const [articleDataState, setArticleDataState] = useState(articleData);

  function createSourceLink(inputString) {
    // Split the string by the colon to extract the name and URL
    const [name, url] = inputString.split(">");

    return (
      <span className="photo_details">
        <a
          className="photo_link"
          href={url + "?utm_source=sacuvaj&utm_medium=referral"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>{" "}
        <a
          href="https://unsplash.com/?utm_source=sacuvaj&utm_medium=referral"
          className="photo_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          (Unsplash)
        </a>
      </span>
    );
  }

  if (forbidden) {
    return (
      <section
        className="websiteTitle websiteTitle--singlePage"
        style={{ height: "300px" }}
      >
        <h1
          className="websiteTitle__headline
        websiteTitle__headline--singlePage"
        >
          Greška 429: Previše zahteva u kratkom roku
        </h1>
      </section>
    );
  }
  if (articleDataState) {
    //? if the article is found
    if (!notFound) {
      const matchedWebsite = articleDataState.find(
        (item) => item.tag === "matched_website"
      );
      let sourceWebsite = "Source";
      const websiteNames = [
        { matched: "blic", fullName: "Blic" },
        { matched: "blic_zena", fullName: "Blic Žena" },
        { matched: "blic_sportal", fullName: "Sportal" },
        { matched: "kurir", fullName: "Kurir" },
        { matched: "kurir_stil", fullName: "Still" },
        { matched: "mondo", fullName: "Mondo" },
        { matched: "mondo_smartlife", fullName: "Smartlife" },
        { matched: "mondo_lepaisrecna", fullName: "Lepa i Srećna" },
        { matched: "telegraf", fullName: "Telegraf" },
        { matched: "telegraf_biznis", fullName: "Telegraf Biznis" },
        { matched: "telegraf_ona", fullName: "Ona" },
        { matched: "alo", fullName: "Alo" },
        { matched: "sport_alo", fullName: "Sport Alo" },
        { matched: "najzena_alo", fullName: "Najžena" },
        { matched: "nova", fullName: "Nova" },
        { matched: "zadovoljna_nova", fullName: "Zadovoljna" },
        { matched: "24sedam", fullName: "24sedam" },
        { matched: "sd", fullName: "Srbija Danas" },
        { matched: "espreso", fullName: "Espreso" },
        { matched: "luftika", fullName: "Luftika" },
        { matched: "b92", fullName: "B92" },
        { matched: "novosti", fullName: "Novosti" },
        { matched: "magazin_novosti", fullName: "Magazin Novosti" },
        { matched: "informer", fullName: "Informer" },
        { matched: "n1info", fullName: "N1" },
        { matched: "sportklubn1info", fullName: "SportKlub" },
        { matched: "republika", fullName: "Republika" },
        { matched: "srecnarepublika", fullName: "Srećna Republika" },
        { matched: "rts", fullName: "RTS" },
        { matched: "danas", fullName: "Danas" },
        { matched: "juznevesti", fullName: "Južne Vesti" },
        { matched: "021", fullName: "021" },
      ];

      if (matchedWebsite) {
        sourceWebsite = websiteNames.find(
          (item) => item.matched === matchedWebsite.text
        );
      }

      const sourceURL = articleDataState.find(
        (item) => item.tag === "source_url"
      );
      const authorName = articleDataState.find(
        (item) => item.tag === "author_span"
      );
      const articleDate = articleDataState.find(
        (item) => item.tag === "date_span"
      );
      return (
        <section
          className="websiteTitle websiteTitle--singlePage"
          style={{
            backgroundImage:
              "linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(" +
              featuredImageURL +
              ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "60%",
          }}
        >
          {articleDataState.map((element, index) => {
            if (element.tag === "h1") {
              return (
                <h1
                  key={index}
                  className="websiteTitle__headline websiteTitle__headline--singlePage"
                >
                  {element.text}
                </h1>
              );
            } else {
              return null;
            }
          })}

          <section className="authorData">
            {authorName && (
              <span className="authorData__publishInformation">
                {authorName.text}
              </span>
            )}
            {articleDate && (
              <span className="authorData__publishInformation authorData__publishInformation--calendar">
                {articleDate.text}
              </span>
            )}
            {sourceURL && sourceWebsite && (
              <span className="authorData__publishInformation authorData__publishInformation--source">
                <a
                  className="authorData__sourceLink"
                  target="_blank"
                  rel="noreferrer"
                  href={sourceURL.text}
                >
                  {sourceWebsite.fullName}
                </a>
              </span>
            )}
            {createSourceLink(photoInfo)}
          </section>
        </section>
      );
    }
  } else {
    {
      // *IF ARTICLE IS NOT FOUND, articleData doesn't exist
      return (
        <section
          className="websiteTitle websiteTitle--singlePage"
          style={{ height: "300px" }}
        >
          <h1
            className="websiteTitle__headline
          websiteTitle__headline--singlePage"
          >
            Greška 404: Stranica nije pronađena
          </h1>
        </section>
      );
    }
  }
};
export default ArticleHeadline;
